import { Bars3Icon, UserIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoBlue from "../assets/reccoma.png";
import LogoWhite from "../assets/reccoma-white.png";

function Login() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);

  return (
    <>
      <div
        className={`
        transition-all flex justify-between items-center p-5`}
      >
        <img className="h-[2rem] md:h-[2.5rem] w-auto" src={LogoBlue} alt="" />

        <div className="hidden lg:flex items-center space-x-5 justify-center font-semibold text-primary">
          <a href="/#">HOME</a>
          <a href="/#solution">SOLUTIONS</a>
          <a href="/#about">ABOUT US</a>
          <a href="/#testimonial">TESTIMONIALS</a>
          <a href="/#consultation">FREE CONSULTATION</a>
          <div
            onClick={() => navigate("/login")}
            className={`bg-primary text-white transition-all p-2 rounded-full cursor-pointer`}
          >
            <UserIcon className="h-[1.5rem] w-auto" />
          </div>
        </div>
        <Bars3Icon
          onClick={() => setMenu(!menu)}
          className="h-[2rem] w-auto md:hidden text-primary"
        />
        {/* Mobile Nav  */}
        <div
          className={`fixed left-0 top-0 z-[999] ${
            !menu ? "translate-x-[-100%]" : "translate-x-0"
          } transition-all p-5 flex flex-col space-y-5 bg-white text-primary h-screen w-[70%] font-semibold`}
        >
          <div className="flex items-center justify-between">
            <img className="h-[1.5rem] w-auto" src={LogoBlue} alt="" />
            <XMarkIcon
              onClick={() => setMenu(false)}
              className="h-[1.5rem] w-auto text-primary"
            />
          </div>
          <a href="/#">HOME</a>
          <a href="/#solution">SOLUTIONS</a>
          <a href="/#about">ABOUT US</a>
          <a href="/#testimonial">TESTIMONIALS</a>
          <a href="/#consultation">FREE CONSULTATION</a>
          <div
            onClick={() => navigate("/login")}
            className="bg-primary text-white w-full py-3 text-center"
          >
            LOGIN
          </div>
        </div>
      </div>
      <div className="login-bg h-screen flex items-center justify-center">
        <div className="p-5 bg-white w-[90%] lg:w-[40%]">
          <div className="text-primary font-semibold text-[2rem]">Login</div>
          <div className="mt-3 flex flex-col space-y-3">
            <label>Email</label>
            <input
              className="border w-full py-3 px-2"
              type="email"
              placeholder="Input your email"
            />
          </div>
          <div className="mt-5 flex flex-col space-y-3">
            <label>Password</label>
            <input
              type="password"
              placeholder="Input your password"
              className="border w-full py-3 px-2"
            />
          </div>
          <div className="mt-5 flex items-center space-x-2">
            <input type="checkbox" className="mt-1" />
            <div>Remember Me</div>
          </div>
          <button className="mt-5 w-full bg-primary text-white py-3">
            Login
          </button>
          <button
            onClick={() => navigate("/register")}
            className="mt-5 w-full bg-blue-300 text-white py-3"
          >
            Register
          </button>
        </div>
      </div>
    </>
  );
}

export default Login;
