// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Testimonial1 from "../assets/testimonial-1.jpg";
import Testimonial2 from "../assets/testimonial-2.JPG";
import Testimonial3 from "../assets/testimonial-3.webp";
import Testimonial4 from "../assets/testimonial-4.jpg";
import Testimonial5 from "../assets/testimonial-5.png";

export default function Testimonials() {
  const list = [
    {
      review: `Semenjak Bergabung saya tidak perlu mengelola OTA dan Reservasi yang cukup rumit`,
      name: "Kadek",
      jobTitle: "Owner Warisan Villa",
      image: Testimonial1,
    },
    {
      review: `Ndak terasa sudah 4th join, astungkara blm pernah ada kendala pembayaran ataupun masalah krn semua ditangani secara profesional`,
      name: "Ni Wayan",
      jobTitle: "Owner Dayung Villa",
      image: Testimonial2,
    },
    {
      review: `Saya Benar-benar terbantu dgn adanya reccoma, semua jadi mudah, pembayaran jg tepat waktu`,
      name: "Nyoman",
      jobTitle: "Owner Ratu Guest House",
      image: Testimonial3,
    },
    {
      review: `Okupansi kamar sering full, sampai saya harus nambah karyawan lagi`,
      name: "Dewa",
      jobTitle: "Owner Sarma Homestay",
      image: Testimonial4,
    },
    {
      review: `Gak bingung lagi ngatur kamar meski cuma dikit, karena reccoma sudah ada sistem`,
      name: "Yuni",
      jobTitle: "Owner Santhi House",
      image: Testimonial5,
    },
  ];
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={20}
      loop={true}
      grabCursor={true}
      breakpoints={{
        768: {
          slidesPerView: 3,
        },
        320: {
          slidesPerView: 1,
        },
      }}
      pagination={{ clickable: true }}
      slidesPerView={3}
      className="pb-12"
      autoplay={{
        delay: 1500,
      }}
    >
      {list.map((x) => {
        return (
          <SwiperSlide>
            <div className="rounded-md overflow-hidden bg-white drop-shadow-lg">
              <img className="w-full h-[15rem]" src={x.image} alt="" />
              <div className="p-2">
                <div className="text-[1.2rem] font-semibold">{x.name}</div>
                <div className="mt-1 h-[5rem]">{x.review}</div>
                <div className="text-primary mt-auto py-2 font-semibold">
                  {x.jobTitle}
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
