import {
  BuildingOfficeIcon,
  BuildingStorefrontIcon,
  CurrencyDollarIcon,
  PresentationChartLineIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import React from "react";

function Solution() {
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5 lg:mt-12">
        <div className=" border rounded-md hover:translate-y-[-5%] transition-all cursor-pointer p-5 flex flex-col items-center text-center drop-shadow-lg text-white bg-primary">
          <BuildingStorefrontIcon className="h-[5rem] w-auto" />
          <div className="font-semibold text-[1.25rem] mt-2">
            Distribusi OTA
          </div>
          <div className="mt-3">
            Memastikan Akomodasi anda terdaftar di OTA yang produktif dan
            Memelihara konsistensi harga & ketersediaan kamar melalui sistem
            Channel Manager
          </div>
        </div>
        <div className=" border rounded-md hover:translate-y-[-5%] transition-all cursor-pointer p-5 flex flex-col items-center text-center drop-shadow-lg text-white bg-primary">
          <BuildingOfficeIcon className="h-[5rem] w-auto" />
          <div className="font-semibold text-[1.25rem] mt-2">
            Sistem Reservasi
          </div>
          <div className="mt-3">
            Sistem operasional yang memudahkan pengalokasian pemesanan ke
            masing-masing kamar, registrasi tamu, dan fitur operasional in-house
            lainnya
          </div>
        </div>
        <div className=" border rounded-md hover:translate-y-[-5%] transition-all cursor-pointer p-5 flex flex-col items-center text-center drop-shadow-lg text-white bg-primary">
          <CurrencyDollarIcon className="h-[5rem] w-auto" />
          <div className="font-semibold text-[1.25rem] mt-2">
            Optimalisasi Pendapatan
          </div>
          <div className="mt-3">
            Pengoptimalan harga dinamis untuk meningkatkan Revenue melalui
            Campaign & Visibility Booster sehingga akomodasi mendapatkan
            keuntungan maksimal
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-center mx-auto lg:max-w-[70%] gap-5 mt-5">
        <div className="w-full border rounded-md hover:translate-y-[-5%] transition-all cursor-pointer p-5 flex flex-col items-center text-center drop-shadow-lg text-white bg-primary">
          <WalletIcon className="h-[5rem] w-auto" />
          <div className="font-semibold text-[1.25rem] mt-2">
            Pengelolaan Pembayaran
          </div>
          <div className="mt-3">
            Mengelola invoice dan pembayaran dari semua Channel OTA yang
            memiliki jatuh tempo bervariasi, hanya dalam satu pintu dan satu
            waktu
          </div>
        </div>
        <div className="w-full border rounded-md hover:translate-y-[-5%] transition-all cursor-pointer p-5 flex flex-col items-center text-center drop-shadow-lg text-white bg-primary">
          <PresentationChartLineIcon className="h-[5rem] w-auto" />
          <div className="font-semibold text-[1.25rem] mt-2">
            Optimalisasi Konten
          </div>
          <div className="mt-3">
            Mengoptimalkan konten yang informatif di semua Channel Online dan
            proaktif memantau skor ulasan tamu, serta memaksimalkan Google
            Business untuk meningkatkan pencarian akomodasi oleh pelancong
            seluruh dunia
          </div>
        </div>
      </div>
    </>
  );
}

export default Solution;
