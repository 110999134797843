import { Bars3Icon, UserIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoBlue from "../assets/reccoma.png";
import emailjs from "@emailjs/browser";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Register() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputData, setInputData] = useState({
    email: "",
    namaAkomodasi: "",
    bintang: 0,
    jenisAkomodasi: "",
    jumlahKamar: 0,
    alamatAkomodasi: "",
    namaPemilik: "",
    noHp: "",
    sudahJoin: "Ya",
  });

  const inputHandler = (e) => {
    setInputData((prevState) => {
      return {
        ...prevState,
        [e.target.id]: e.target.value,
      };
    });
  };

  const submitHandler = () => {
    setLoading(true);
    if (
      inputData.email &&
      inputData.namaAkomodasi &&
      inputData.bintang &&
      inputData.jenisAkomodasi &&
      inputData.alamatAkomodasi &&
      inputData.jumlahKamar &&
      inputData.namaPemilik &&
      inputData.noHp &&
      inputData.sudahJoin
    ) {
      emailjs
        .send(
          "service_8hpxreb",
          "template_uvgt38j",
          {
            from_email: inputData.email,
            nama_akomodasi: inputData.namaAkomodasi,
            bintang: inputData.bintang,
            jenis_akomodasi: inputData.jenisAkomodasi,
            jumlah_kamar: inputData.jumlahKamar,
            alamat_akomodasi: inputData.alamatAkomodasi,
            nama_pemilik: inputData.namaPemilik,
            no_hp: inputData.noHp,
            sudah_join: inputData.sudahJoin,
          },
          "SgjHCpj9oQnKPCQ-d"
        )
        .then((response) => {
          toast("Data Sent");
          setInputData({
            email: "",
            namaAkomodasi: "",
            bintang: 0,
            jenisAkomodasi: "",
            jumlahKamar: 0,
            alamatAkomodasi: "",
            namaPemilik: "",
            noHp: "",
            sudahJoin: "Ya",
          });
        })
        .catch((error) => toast(error.message));
    } else {
      toast("Please input valid data");
    }
    setLoading(false);
  };

  return (
    <>
      <div
        className={`
        transition-all flex justify-between items-center p-5`}
      >
        <img className="h-[2rem] md:h-[2.5rem] w-auto" src={LogoBlue} alt="" />

        <div className="hidden lg:flex items-center space-x-5 justify-center font-semibold text-primary">
          <a href="/#">HOME</a>
          <a href="/#solution">SOLUTIONS</a>
          <a href="/#about">ABOUT US</a>
          <a href="/#testimonial">TESTIMONIALS</a>
          <a href="/#consultation">FREE CONSULTATION</a>
          <div
            onClick={() => navigate("/login")}
            className={`bg-primary text-white transition-all p-2 rounded-full cursor-pointer`}
          >
            <UserIcon className="h-[1.5rem] w-auto" />
          </div>
        </div>
        <Bars3Icon
          onClick={() => setMenu(!menu)}
          className="h-[2rem] w-auto md:hidden text-primary"
        />
        {/* Mobile Nav  */}
        <div
          className={`fixed left-0 top-0 z-[999] ${
            !menu ? "translate-x-[-100%]" : "translate-x-0"
          } transition-all p-5 flex flex-col space-y-5 bg-white text-primary h-screen w-[70%] font-semibold`}
        >
          <div className="flex items-center justify-between">
            <img className="h-[1.5rem] w-auto" src={LogoBlue} alt="" />
            <XMarkIcon
              onClick={() => setMenu(false)}
              className="h-[1.5rem] w-auto text-primary"
            />
          </div>
          <a href="/#">HOME</a>
          <a href="/#solution">SOLUTIONS</a>
          <a href="/#about">ABOUT US</a>
          <a href="/#testimonial">TESTIMONIALS</a>
          <a href="/#consultation">FREE CONSULTATION</a>
          <div
            onClick={() => navigate("/login")}
            className="bg-primary text-white w-full py-3 text-center"
          >
            LOGIN
          </div>
        </div>
      </div>
      <div className="login-bg h-screen flex items-center justify-center">
        <div className="p-5 max-h-[90%] overflow-y-auto w-[90%] lg:w-[40%] bg-white flex flex-col space-y-5">
          <div className="text-[1.5rem] font-semibold text-primary">
            Create Account
          </div>
          <div className="flex flex-col space-y-3">
            <label>Email</label>
            <input
              onChange={(e) => inputHandler(e)}
              value={inputData.email}
              id="email"
              type="email"
              className="border w-full py-3 px-2 outline-none"
              placeholder="Input your email"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label>Nama Akomodasi</label>
            <input
              value={inputData.namaAkomodasi}
              onChange={(e) => inputHandler(e)}
              id="namaAkomodasi"
              className="border w-full py-3 px-2 outline-none"
              placeholder="Masukkan Nama Akomodasi"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label>Bintang</label>
            <select
              value={inputData.bintang}
              onChange={(e) => inputHandler(e)}
              id="bintang"
              className="border w-full py-3 px-2 outline-none"
            >
              <option>0</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
          <div className="flex flex-col space-y-3">
            <label>Jenis Akomodasi</label>
            <input
              value={inputData.jenisAkomodasi}
              onChange={(e) => inputHandler(e)}
              id="jenisAkomodasi"
              className="py-3 px-2 w-full outline-none border"
              placeholder="Masukkan Jenis Akomodasi"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label>Jumlah Kamar</label>
            <input
              value={inputData.jumlahKamar}
              id="jumlahKamar"
              onChange={(e) => inputHandler(e)}
              type="number"
              placeholder="Masukkan Jumlah Kamar"
              className="border w-full py-3 px-2 outline-none"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label>Alamat Akomodasi</label>
            <input
              value={inputData.alamatAkomodasi}
              onChange={(e) => inputHandler(e)}
              id="alamatAkomodasi"
              placeholder="Masukkan Alamat Akomodasi"
              className="border w-full py-3 px-2 outline-none"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label>Nama Pemilik</label>
            <input
              value={inputData.namaPemilik}
              onChange={(e) => inputHandler(e)}
              id="namaPemilik"
              placeholder="Masukkan Nama Pemilik"
              className="border w-full py-3 px-2 outline-none"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label>No HP</label>
            <input
              value={inputData.noHp}
              onChange={(e) => inputHandler(e)}
              id="noHp"
              type="number"
              placeholder="Masukkan Nomor HP"
              className="border w-full py-3 px-2 outline-none"
            />
          </div>
          <div className="flex items-center space-x-5">
            <label>Sudah Join OTA ?</label>
            <div className="flex items-center space-x-5">
              <div className="flex items-center space-x-2">
                <input
                  onChange={(e) => inputHandler(e)}
                  name="join"
                  id="sudahJoin"
                  type="radio"
                  className="mt-1"
                  value="Ya"
                />
                <div>Ya</div>
              </div>
              <div className="flex items-center space-x-2">
                <input
                  onChange={(e) => inputHandler(e)}
                  name="join"
                  id="sudahJoin"
                  type="radio"
                  className="mt-1"
                  value="Tidak"
                />
                <div>Tidak</div>
              </div>
            </div>
          </div>
          <button
            onClick={() => submitHandler()}
            className="w-full py-3 bg-primary text-white font-semibold"
          >
            {loading ? "Loading..." : "Register"}
          </button>
          <button
            onClick={() => navigate("/login")}
            className="w-full py-3 bg-blue-300 text-white font-semibold"
          >
            Login
          </button>
        </div>
      </div>
    </>
  );
}

export default Register;
