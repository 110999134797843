import React, { useState } from "react";
import LogoBlue from "../assets/reccoma.png";
import LogoWhite from "../assets/reccoma-white.png";
import { useNavigate } from "react-router-dom";
import { Bars3Icon, XMarkIcon, UserIcon } from "@heroicons/react/24/outline";

function Navigation({ floatingNav }) {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  return (
    <div
      className={`${
        floatingNav
          ? "fixed top-0 left-0 w-full bg-white text-primary z-[999] font-semibold"
          : " "
      } transition-all flex justify-between items-center p-5`}
    >
      <div>
        {floatingNav ? (
          <img
            className="h-[2rem] md:h-[2.5rem] w-auto"
            src={LogoBlue}
            alt=""
          />
        ) : (
          <img
            className="h-[2rem] md:h-[2.5rem] w-auto"
            src={LogoWhite}
            alt=""
          />
        )}
      </div>
      <div className="hidden lg:flex items-center space-x-5 justify-center">
        <a href="#">HOME</a>
        <a href="#solution">SOLUTIONS</a>
        <a href="#about">ABOUT US</a>
        <a href="#testimonial">TESTIMONIALS</a>
        <a href="#consultation">FREE CONSULTATION</a>
        <div
          onClick={() => navigate("/login")}
          className={`${
            floatingNav ? "bg-primary text-white" : ""
          } transition-all p-2 cursor-pointer rounded-full`}
        >
          <UserIcon className="h-[1.5rem] w-auto" />
        </div>
      </div>
      <Bars3Icon
        onClick={() => setMenu(!menu)}
        className="h-[2rem] w-auto md:hidden"
      />
      {/* Mobile Nav  */}
      <div
        className={`fixed left-0 top-0 z-[999] ${
          !menu ? "translate-x-[-100%]" : "translate-x-0"
        } transition-all p-5 flex flex-col space-y-5 bg-white text-primary h-screen w-[70%] font-semibold`}
      >
        <div className="flex items-center justify-between space-x-1">
          <img className="h-[1.5rem] w-auto" src={LogoBlue} alt="" />
          <XMarkIcon
            onClick={() => setMenu(false)}
            className="h-[1.5rem] w-auto text-primary"
          />
        </div>
        <a href="#">HOME</a>
        <a href="#solution">SOLUTIONS</a>
        <a href="#about">ABOUT US</a>
        <a href="#testimonial">TESTIMONIALS</a>
        <a href="#consultation">FREE CONSULTATION</a>
        <div
          onClick={() => navigate("/login")}
          className="bg-primary text-white w-full py-3 text-center"
        >
          LOGIN
        </div>
      </div>
    </div>
  );
}

export default Navigation;
