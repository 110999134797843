import React from "react";

// Images
import Facebook from "../assets/facebook.svg";
import Instagram from "../assets/instagram.svg";
import LinkedIn from "../assets/linkedin.svg";
import Whatsapp from "../assets/whatsapp.svg";

function Footer() {
  return (
    <div id="contact" className="p-5 lg:px-[5%] bg-primary text-white">
      <div className="flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-12 py-5">
        {/* <div className="lg:max-w-[40%]">
          <div className="text-[1.2rem] font-semibold">
            FREE WORDS ABOUT RECCOMA
          </div>
          <div className="mt-2">
            {" "}
            Memudahkan bisnis Akomodasi Independen, sehingga owner akomodasi
            dapat fokus meningkatkan mutu pelayanan kepada customer & Reccoma
            akan meaksimalkan penjualan akomodasi melalui Online Channel
          </div>
        </div> */}
        <div className="">
          <div className="font-semibold text-[1.2rem]">Site Map</div>
          <div className="flex flex-col space-y-2 mt-2">
            <a href="#home">Home</a>
            <a href="#solution">Solution</a>
            <a href="#aboute">About Us</a>
            <a href="#testimonial">Testimonials</a>
            <a href="#consultation">Free Consultation</a>
          </div>
        </div>
        <div className="">
          <div className="font-semibold text-[1.2rem]">Tools</div>
          <div className="flex flex-col space-y-2 mt-2">
            <a href="#">Login</a>
            <a href="#">Register</a>
          </div>
        </div>
        <div className="text-[1.2rem] font-semibold">
          <div>Follow Reccoma On Social Media</div>
          <div className="mt-3 flex items-center space-x-5">
            <a
              href="https://instagram.com/reccoma.id?igshid=YmMyMTA2M2Y="
              target="_blank"
              rel="noreferrer"
            >
              <img className="h-[1.5rem] w-auto" src={Instagram} alt="" />
            </a>
            <a
              href="https://m.facebook.com/reccoma/?ref=pages_you_manage"
              target="_blank"
              rel="noreferrer"
            >
              <img className="h-[1.5rem] w-auto" src={Facebook} alt="" />
            </a>
            <a
              href="https://www.linkedin.com/company/reccoma/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="h-[1.5rem] w-auto" src={LinkedIn} alt="" />
            </a>
            <a
              href="https://wa.me/message/42NS3T5FSUB5M1"
              target="_blank"
              rel="noreferrer"
            >
              <img className="h-[1.5rem] w-auto" src={Whatsapp} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center border-gray-100 font-light pt-5">
        Copyright © 2022 PT Asthatech Rekomendasi Bangsa.
      </div>
    </div>
  );
}

export default Footer;
