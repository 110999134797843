import React, { useEffect, useRef, useState } from "react";
import Navigation from "../components/Navigation";

// Images
import Facebook from "../assets/facebook.svg";
import Instagram from "../assets/instagram.svg";
import LinkedIn from "../assets/linkedin.svg";
import Whatsapp from "../assets/whatsapp.svg";

import Illustration from "../assets/travel-agent.jpg";
import Partner from "../assets/partner.jpg";

import {
  ChevronUpIcon,
  EnvelopeIcon,
  MapIcon,
  PhoneIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import Solution from "../components/Solution";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";

import Illustration2 from "../assets/illustration.png";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const homeRef = useRef(null);
  const [floatingNav, setFloatingNav] = useState(false);
  useEffect(() => {
    const obsCallback = (entries) => {
      if (entries[0].isIntersecting) {
        setFloatingNav(false);
      } else {
        setFloatingNav(true);
      }
    };

    const sectionObserver = new IntersectionObserver(obsCallback, {
      root: null,
      threshold: 0.3,
    });

    sectionObserver.observe(homeRef.current);
  }, [homeRef]);

  return (
    <div>
      <div
        onClick={() => window.scrollTo(0, 0)}
        className={`${
          floatingNav ? "opacity-1 visible" : "opacity-0 invisible"
        } transition-all bg-primary text-white p-2 rounded-full fixed bottom-[2%] right-[1%] z-[999] cursor-pointer`}
      >
        <ChevronUpIcon className="h-[2rem] w-auto" />
      </div>
      {/* Section 1  */}
      <div className="landing-bg h-screen text-white">
        <div
          className="flex flex-col w-full h-full"
          style={{ background: "rgba(0,0,0,0.5)" }}
        >
          <Navigation floatingNav={floatingNav} />
          <div
            ref={homeRef}
            className="p-5 text-white w-full flex text-center flex-col items-center justify-center h-full"
          >
            <div className="text-[2rem] lg:text-[3rem] font-bold">
              OTA MANAGEMENT SERVICES
            </div>
            <div className="text-center lg:max-w-[60%] mt-2">
              Memudahkan bisnis Akomodasi Independen, sehingga owner akomodasi
              dapat fokus meningkatkan mutu pelayanan kepada customer & Reccoma
              akan memaksimalkan penjualan akomodasi melalui Online Channel
            </div>
            <a
              href="#solution"
              className="bg-primary hover:brightness-110 border-[2px] border-primary transition-all mt-6 px-4 py-3 text-[1.25rem]"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>

      {/* Section 2  */}
      <div id="solution" className="p-5 lg:px-[5%] py-12 lg:py-36 bg-[#FBFBFB]">
        <div className="text-center text-[2rem] text-primary font-semibold lg:max-w-[80%] mx-auto">
          Solusi yang kami berikan untuk owner akomodasi
        </div>
        <Solution />
      </div>

      {/* Section 3  */}
      <div id="about" className="p-5 lg:px-[5%] py-12 bg-white">
        <div className="flex flex-col items-center md:flex-row md:space-x-12">
          <div className="w-full">
            <div className="text-primary text-[1.25rem] font-bold">
              ABOUT US
            </div>
            <div className="mt-3">
              Pertumbuhan penjualan akomodasi melalui Online Travel Agent (OTA)
              melaju pesat, Mendominasi hampir 80% okupansi kamar akomodasi
              bintang 0-3, namun tidak dipungkiri pengelolaannya cukup rumit dan
              membutuhkan strategi cukup dinamis, hal ini menjadikan Reccoma
              hadir untuk membantu owner akomodasi agar lebih mudah dalam
              menjalankan bisnis, dengan Teknologi yang kami gunakan dan team
              profesional yang dapat mendorong distribusi OTA lebih maksimal.
            </div>
          </div>
          <div className="w-full rounded-md mt-5 md:mt-0">
            <img className="rounded-md" src={Illustration} alt="" />
          </div>
        </div>
      </div>

      {/* Section 4  */}
      <div className="flex flex-col p-5 lg:px-[5%] md:flex-row items-center md:space-x-12 bg-[#FBFBFB] py-12 lg:py-24">
        <div className="w-full">
          <img className="rounded-md" src={Partner} alt="" />
        </div>
        <div className="w-full mt-5 md:mt-0">
          <div className="text-primary font-bold text-[1.5rem] mt-1 md:mt-3">
            Kenapa Jadi Partner Reccoma ?
          </div>
          <div className="flex flex-col space-y-2 mt-2">
            <div className="flex space-x-1">
              <div>1.</div>
              <div>
                Pengelolaan saluran online sangat rumit oleh karena itu kami
                mempermudahnya
              </div>
            </div>

            <div className="flex space-x-1">
              <div>2.</div>
              <div>
                Sistem Operasional membutuhkan teknologi yang relatif mahal dan
                kami menanggungnya
              </div>
            </div>
            <div className="flex space-x-1">
              <div>3.</div>
              <div>
                Melibatkan sumber daya khusus dan kami siap membantu anda
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 5  */}
      <div id="testimonial" className="p-5 lg:px-[5%] py-12">
        <div className="text-center text-[2rem] font-semibold text-primary">
          Apa Kata Partner Kami ?
        </div>
        <div className="mt-5 lg:mt-12">
          <Testimonials />
        </div>
      </div>

      {/* Section 6  */}
      <div id="consultation" className="p-5 lg:px-[5%] bg-[#FBFBFB] py-12">
        <div className="text-center text-[2rem] font-semibold text-primary">
          Bergabung Menjadi Partner Kami
        </div>
        <div className="flex flex-col-reverse md:flex-row md:space-x-5 mt-5 lg:mt-12">
          <div className="w-full mt-5 md:mt-0">
            <div className="font-semibold text-[1.5rem]">Hubungi Kami</div>
            <div className="flex flex-col space-y-3 mt-3">
              <div className="flex items-center space-x-3">
                <PhoneIcon className="h-[1.5rem] text-primary w-auto" />
                <div>+6285738619918</div>
                <div>+623614773914</div>
              </div>
              <div className="flex items-center space-x-3">
                <EnvelopeIcon className="h-[1.5rem] text-primary w-auto" />
                <div>reccoma.id@gmail.com</div>
              </div>
              <a
                href="https://www.google.com/maps/place/Reccoma+-+OTA+Management+Services/@-8.7956622,115.1948075,15z/data=!4m2!3m1!1s0x0:0xf49ccc160beec08d?sa=X&ved=2ahUKEwiO-fHZ-rn7AhUETmwGHSljDMwQ_BJ6BAhrECE"
                target="_blank"
                className="flex items-center space-x-3"
                rel="noreferrer"
              >
                <MapIcon className="h-[1.5rem] text-primary w-auto" />
                <div>
                  The Living Hill Residence Blok. A6 Benoa, Bali-Indonesia
                </div>
              </a>
              <div className="flex items-center space-x-3">
                <svg
                  className="text-primary h-[1.5rem] w-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M16.36 14c.08-.66.14-1.32.14-2c0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2m-5.15 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56M14.34 14H9.66c-.1-.66-.16-1.32-.16-2c0-.68.06-1.35.16-2h4.68c.09.65.16 1.32.16 2c0 .68-.07 1.34-.16 2M12 19.96c-.83-1.2-1.5-2.53-1.91-3.96h3.82c-.41 1.43-1.08 2.76-1.91 3.96M8 8H5.08A7.923 7.923 0 0 1 9.4 4.44C8.8 5.55 8.35 6.75 8 8m-2.92 8H8c.35 1.25.8 2.45 1.4 3.56A8.008 8.008 0 0 1 5.08 16m-.82-2C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2c0 .68.06 1.34.14 2M12 4.03c.83 1.2 1.5 2.54 1.91 3.97h-3.82c.41-1.43 1.08-2.77 1.91-3.97M18.92 8h-2.95a15.65 15.65 0 0 0-1.38-3.56c1.84.63 3.37 1.9 4.33 3.56M12 2C6.47 2 2 6.5 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
                  />
                </svg>
                <div>www.reccoma.com</div>
              </div>
              <div>
                <div className="text-[1.5rem] font-semibold mt-5">
                  Follow Our Social Media
                </div>
                <div className="mt-3 flex items-center space-x-5">
                  <div className="p-2 rounded-full bg-primary hover:translate-y-[-5%] transition-all">
                    <a
                      href="https://instagram.com/reccoma.id?igshid=YmMyMTA2M2Y="
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img className="h-[2rem] w-auto" src={Instagram} alt="" />
                    </a>
                  </div>
                  <div className="p-2 rounded-full bg-primary hover:translate-y-[-5%] transition-all">
                    <a
                      href="https://m.facebook.com/reccoma/?ref=pages_you_manage"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img className="h-[2rem] w-auto" src={Facebook} alt="" />
                    </a>
                  </div>
                  <div className="p-2 rounded-full bg-primary hover:translate-y-[-5%] transition-all">
                    <a
                      href="https://www.linkedin.com/company/reccoma/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img className="h-[2rem] w-auto" src={LinkedIn} alt="" />
                    </a>
                  </div>
                  <div className="p-2 rounded-full bg-primary hover:translate-y-[-5%] transition-all">
                    <a
                      href="https://wa.me/message/42NS3T5FSUB5M1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img className="h-[2rem] w-auto" src={Whatsapp} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <img src={Illustration2} alt="" />
            <div className="flex justify-center ">
              <div
                className="px-5 py-3 bg-primary text-white rounded-full cursor-pointer hover:brightness-110 transition-all"
                onClick={() => navigate("/register")}
              >
                Konsultasi Gratis
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer  */}
      <Footer />
    </div>
  );
}

export default Home;
